<mat-toolbar color="secondary">
    <mat-toolbar-row>
        <a href="/products" mat-button>Products</a>
        <a href="/cart" mat-button>Cart</a>
    </mat-toolbar-row>
</mat-toolbar>

<div class="container text-center">
    <h1>Amazing Animal Paintings</h1>
    <h3>Make your home a special place. With our beautiful and unique artwork.</h3>
</div>

<router-outlet></router-outlet>