<div class="container-sm">
    <h1>Your cart</h1>

    <mat-card>
        <mat-list>
            <mat-list-item *ngFor="let item of cartItems">
                <span matListItemTitle>{{ item.name }}</span>
                <span matListItemLine>{{ item.price  | currency: 'USD' }}</span>
            </mat-list-item>

            <mat-list-item>
                <span class="price" matListItemTitle>Total (USD)</span>
                <span class="price" matListItemLine>{{ +totalPrice | currency: 'USD' }}</span>
            </mat-list-item>
      
            <mat-list-item class="text-right">
                <button mat-flat-button (click)="clearCart()">Clear</button>
            </mat-list-item>

            <mat-list-item class="text-right">
                <button mat-raised-button color="accent" (click)="checkout()">Checkout</button>
            </mat-list-item>
        </mat-list>
    </mat-card>
</div>