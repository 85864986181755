<div class="container">
    <h2>Out latest collection</h2>

    <mat-form-field>
        <input matInput (keyup)="applyFilter($event)" placeholder="Search">
    </mat-form-field>

    <mat-form-field class="sort-dropdown">
        <mat-select (selectionChange)="sortProdutcs($event.value)" placeholder="Sort">
            <mat-option value="priceLowToHigh">Price: Low to High</mat-option>
            <mat-option value="priceHighToLow">Price: High to Low</mat-option>
        </mat-select>
    </mat-form-field>

    <div fxLayout="row wrap" fxLayoutGap="50px grid">
        <div *ngFor="let product of filteredProducts">
            <mat-card class="product-card" appearance="outlined" (click)="addToCart(product)">
                <img mat-card-image src="assets/images/{{product.image_url}}">
                <div mat-card-avatar class="example-header-image"></div>
                <mat-card-content>
                    <mat-card-title>{{ product.name }}</mat-card-title>
                    <mat-card-subtitle>{{ product.price | currency:"USD" }}</mat-card-subtitle>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>